import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, delay, Observable, of, Subject, interval } from 'rxjs';
import { environment } from '@pos/shared/environments';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '@pos/ezisend/shared/ui/dialogs/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginFacade } from '@pos/ezisend/auth/data-access/store';
import { IPickupAddress } from '@pos/ezisend/profile/data-access/models';
import { map, share, take, takeUntil } from 'rxjs/operators';
import { bm } from '../../../../../assets/my';
import { en } from '../../../../../assets/en';
import { TranslationService } from '../../../../../shared-services/translate.service';
import { DOCUMENT } from '@angular/common';
declare const window: any;

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private clock: Observable<any> | undefined;
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable();
  notifier = new Subject();
  /* Default Country */
  defaultCountry = 'Malaysia';
  defaultCountryCode = 'MY';
  defaultDialingCode = '+60';
  isLocalCountryMY = this.defaultCountry?.toLowerCase() === 'malaysia';

  private getCountry: BehaviorSubject<any> = new BehaviorSubject({
    data: this.defaultCountry,
    isParcel: undefined,
  });

  //DOWNTIME CONFIG

  public time:any;
  mDate:any = '2/25/2023';
  fromDate:any = '2/25/2023, 6:00:00 AM';
  toDate:any = '2/25/2023, 9:00:00 AM';
  actualSchedule = '25 February 2023, 6am - 9am';
  // DOWNTIME CONFIG ENDS

  private getSenderAddress: BehaviorSubject<any> = new BehaviorSubject(null);
  private getRecipientData: BehaviorSubject<any> = new BehaviorSubject('');
  private isCountryMY: BehaviorSubject<any> = new BehaviorSubject(false);
  private getPickupID: BehaviorSubject<any> = new BehaviorSubject('');
  private getShipmentData: BehaviorSubject<any> = new BehaviorSubject(null);
  private recipientDetail: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private parcelDetail: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private customDetail: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isCOD:BehaviorSubject<boolean> = new BehaviorSubject(false);
  isCODUbat:BehaviorSubject<boolean> = new BehaviorSubject(false);
  isMelPlus:BehaviorSubject<boolean> = new BehaviorSubject(false);
  isMPS:BehaviorSubject<boolean> = new BehaviorSubject(false);
  isMelPlusSelected:BehaviorSubject<boolean> = new BehaviorSubject(false);
  isMPSSelected:BehaviorSubject<boolean> = new BehaviorSubject(false);
  masterAccount:BehaviorSubject<boolean> = new BehaviorSubject(false);
  $totalRequestPickUp:BehaviorSubject<any> = new BehaviorSubject(0);
  contactOptionSelected = new Subject<any>();
  recipientForm: Subject<boolean> = new Subject();
  selectedPlugin:BehaviorSubject<any> = new BehaviorSubject<any>(localStorage['selectedPlugin']);
  _selectedTab = {
    index: 0
  };

  getCurrentRecipientData$ = this.getRecipientData.asObservable();
  getHSC$!: Observable<any>;
  countryList$!: Observable<any>;
  getCurrentCountry$ = this.getCountry.asObservable();
  getSenderAddress$ = this.getSenderAddress.asObservable();
  getCurrentIsCountryMY$ = this.isCountryMY.asObservable();
  getSelectedPickUp$ = this.getPickupID.asObservable();
  getShipmentData$ = this.getShipmentData.asObservable();
  getRecipientDetail$ = this.recipientDetail.asObservable();
  getParcelDetail$ = this.parcelDetail.asObservable();
  getCustomDetail$ = this.customDetail.asObservable();

  private onTableLoad = new BehaviorSubject<boolean>(false);
  $onTableLoad = this.onTableLoad.asObservable();

  private currentSelectedPickupAddress: BehaviorSubject<IPickupAddress | null> = new BehaviorSubject<IPickupAddress | null>(null);
  getCurrentSelectedPickupAddress$ = this.currentSelectedPickupAddress.asObservable();

  /* REGEX  start*/
  numericWithDecimalOnly = /^(?!0*(\.0+)?$)(\d+|\d*\.\d{1,2})?$/;
  numericOnly = /^[0-9]\d*$/;
  alphaOnly = /^[a-zA-Z0-9]+$/;
  emailOnly = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  /* REGEX  end*/

  header = {
    headers: new HttpHeaders().set(
      'Authorization',
      'Basic ZnVHdXQwZ0IycUE2aDJKeUpxVTpJakliRUQ2Z08xbTZpSFZlaWhsbWI4cTdWeVV2TDU='
    ),
  };

  /* URLs */
  flagAPI = environment.flagAPI;
  MDMAPI = environment.MDMAPI;
  SPPAPI = environment.sppUatUrl;
  invoiceApi = environment.invoiceUrl.invoiceApi;
  clientId = environment.invoiceUrl.clientId;
  clientSecret = environment.invoiceUrl.clientSecret;

  /* Language */
  public languageData: any = (localStorage.getItem("language") && localStorage.getItem("language") === 'en') ? en.data.dialog_box_data :
  (localStorage.getItem("language") && localStorage.getItem("language") === 'my') ? bm.data.dialog_box_data :
    en.data.dialog_box_data;

  reportApi = environment.reportUrl.reportApi;
  reportAuthApi = environment.reportUrl.reportAuthApi;
  reportClientId = environment.reportUrl.clientId;
  reportClientSecret = environment.reportUrl.clientSecret;
  isValidEmail$ = new BehaviorSubject<string>('');

  constructor(
    private http: HttpClient,
    private router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private loginFacade: LoginFacade,
    private translate: TranslationService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.clock = interval(1000).pipe(map(() =>
    {
      const getDt = new Date(new Date().toLocaleString("en-US", {
        timeZone: "Asia/Kuala_Lumpur"
      }));
      const getLocal = getDt.toLocaleString("en-US", {
        timeZone: "Asia/Kuala_Lumpur"
      });
      return getLocal;
    }

    ));

    this.translate.buttonClick$.subscribe(() => {
      if (localStorage.getItem("language") == "en") {
        this.languageData = en.data.dialog_box_data
      }
      else if (localStorage.getItem("language") == "my") {
        this.languageData = bm.data.dialog_box_data
      }
    })
  }
  isMobile: any = false;

  public checkIfMobile() {
    this.isMobile = window.innerWidth < 768;
    return this.isMobile;
  }

  getCurrentTime() {
    return this.clock;
  }

  downTimeChkIn(mDate?:any, fromDt?:any, toDt?:any) {
    if(new Date(mDate).toLocaleDateString() === new Date().toLocaleDateString()) {
      this.time = this.getCurrentTime();
      this.time
      .pipe(
        take(1),
        takeUntil(this.notifier)
      )
      .subscribe((data:any) => {
        if(!((new Date(data).toLocaleString() > (new Date(fromDt).toLocaleString())) && (new Date(data).toLocaleString() < (new Date(toDt).toLocaleString())))) {
          this.router.navigate(['/auth/login']);
        } else {
          this.router.navigate(['/downtime']);
        }
      });
    }
    else {
      this.router.navigate(['/auth/login']);
    }
  }


  downTimeChkOut(mDate?:any, fromDt?:any, toDt?:any) {
    if(new Date(mDate).toLocaleDateString() === new Date().toLocaleDateString()) {
      this.time = this.getCurrentTime();
      this.time
      .pipe(
        take(1),
        takeUntil(this.notifier)
      )
      .subscribe((data:any) => {
        if(((new Date(data).toLocaleString() > (new Date(fromDt).toLocaleString())) && (new Date(data).toLocaleString() < (new Date(toDt).toLocaleString())))) {
          this.logout();
          this.router.navigate(['/downtime']);
        }
      });
    }
  }

   fetchBillingData(accessToken : string, accountNumber : string) {
    const headers = new HttpHeaders({
      Authorization: accessToken
    });
    const body = {
      accountNumber: accountNumber,
    };
    return this.http.post<any>(
      `${this.invoiceApi}`,
      body,
      { headers }
    );
  }

  tabChanged(value:any) {
    this._selectedTab = value;
  }

  downloadReceipt (accessToken: string, id: string) {
    const headers = new HttpHeaders({
      Authorization: accessToken,
      'Content-Type': 'application/octet-stream',
    });

    return this.http.get<any>(
      `${this.invoiceApi}/${id}`,
      {
        headers, responseType: 'blob' as 'json', observe: 'response' // Set the responseType to 'blob' to handle binary data
      }
    );
  }

  getInvoiceAccessToken() {
    const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
    });
    return this.http.post<any>(
      `https://datapos.auth.ap-southeast-1.amazoncognito.com/oauth2/token`,
      `grant_type=client_credentials&client_id=${this.clientId}&client_secret=${this.clientSecret}`,
      { headers }
    );
  }

  getReportAccessToken() {
    const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
    });
    return this.http.post<any>(
      `${this.reportAuthApi}`,
      `grant_type=client_credentials&client_id=${this.reportClientId}&client_secret=${this.reportClientSecret}`,
      { headers }
    );
  }

  fetchReportsData(accessToken : any, accountNumber : any) {
    const headers = new HttpHeaders({
      Authorization: accessToken,
    });
    return this.http.get<any>(
      `${this.reportApi}${accountNumber}/report`,
      { headers }
    );
  }

  downloadReport(accessToken: string, filename: string, accountNumber: string){
    const headers = new HttpHeaders({
      Authorization: accessToken,
      filename: filename
      // 'Content-Type': 'application/octet-stream',
    });

    return this.http.get<any>(
      `${this.reportApi}${accountNumber}/report/download`,
      {
        headers, responseType: 'blob' as 'json', observe: 'response' // Set the responseType to 'blob' to handle binary data
      }
    );
  }

  getAPI(endpoint: string, query: string, withDelay = 0) {
    /* BASE URL HAVE TO MOVE TO ENVIRONMENT */
    return this.http
      .get(`${this.MDMAPI}${endpoint}/v1/${query}`)
      // .pipe(delay(withDelay));
      .pipe(share());
  }

  fetchList = (endpoint: any, query: any): Observable<any> => {
    return this.http.get<any>(`${this.SPPAPI}${endpoint}/v1/${query}`).pipe(share()).pipe(catchError((x) => of(x)));
  };

  fetchListv2 = (endpoint: any, query: any): Observable<any> => {
    return this.http.get<any>(`${this.SPPAPI}${endpoint}/v2/${query}`).pipe(share()).pipe(catchError((x) => of(x)));
  };

  submitData = (endpoint: any, query: any, data_body: any): Observable<any> => {
    return this.http.post<any>(
      `${this.SPPAPI}${endpoint}/v1/${query}`,
      data_body
    );
  };

  submitDataV2 = (endpoint: any, query: any, data_body: any): Observable<any> => {
    return this.http.post<any>(
      `${this.SPPAPI}${endpoint}/v2/${query}`,
      data_body
    );
  };

  submitDataV3 = (endpoint: any, query: any, data_body: any): Observable<any> => {
    return this.http.post<any>(
      `${this.SPPAPI}${endpoint}/v3/${query}`,
      data_body
    );
  };

  changePwd = (
    endpoint: any,
    query: any,
    data_body: any,
    header: any
  ): Observable<any> => {
    return this.http.post<any>(
      `${this.SPPAPI}${endpoint}/v1/${query}`,
      data_body,
      header
    );
  };

  getCountryValue(val: any) {
    this.getCountry.next(val);
  }

  isLoading(val: any) {
    this._loading.next(val);
  }

  setRecipientValue(val: any) {
    this.getRecipientData.next(val);
  }

  getRecipientValue() {
    return this.getRecipientData.getValue();
  }

  getCountryIsMY(val = false) {
    this.isCountryMY.next(val);
  }

  setSelectedPickUpID(val: any) {
    this.getPickupID.next(val);
  }

  setSelectedShipmentData(val: any) {
    this.getShipmentData.next(val);
  }

  setCurrentSelectedPickupAddress(val?: IPickupAddress) {
    this.currentSelectedPickupAddress.next(val??null);
  }

  setSenderAddress(val: any) {
    this.getSenderAddress.next(val);
  }

  getSelectedPickupID() {
    return this.getPickupID.getValue();
  }

  setRecipientDetail(val: any) {
    this.recipientDetail.next(val);
  }

  setParcelDetail(val: any) {
    this.parcelDetail.next(val);
  }

  setCustomDetail(val: any) {
    this.customDetail.next(val);
  }

  formErrorHandler(form: FormGroup, field: string, val: string) {
    return form.controls[field].hasError(val);
  }

  redirectTo(uri: string, query?: any) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri], { queryParams: query }));
  }

  /** NOTE: below function is not used anywhere in this appliaction */
  // private get currentCountry(): string {
  //   return new Date().toString().split('(')[1].split(' ')[0];
  // }

  openErrorDialog(title?:string, desc?: string, action?:string, code?:any) {
    const dialogConfigErr = new MatDialogConfig();
    dialogConfigErr.disableClose = true;
    dialogConfigErr.autoFocus = true;
    dialogConfigErr.height = '400px'
    dialogConfigErr.maxWidth='680px';
    dialogConfigErr.data = {
      descriptions: desc ? desc : this.languageData.error_msg,
      title: title ? title : this.languageData.error_title,
      icon: 'warning',
      confirmEvent: true,
      actionText: action
    }
    const dialogRef = this.dialog.open(DialogComponent, dialogConfigErr);
    const dialogSubmitSubscription =
      dialogRef.componentInstance.confirmEvent.subscribe(() => {
        dialogSubmitSubscription.unsubscribe();
        dialogRef.close();
      });
  }

  openCustomErrorDialog(err: any) {
    const errorMessage = err?.error?.error?.data?.message
      ? err?.error?.error?.data?.message
      : err?.error?.error?.code === 'E1001'
      ? this.languageData.error_message1
      : err?.error?.error?.code === 'E1005'
      ? this.languageData.error_message2
      : err?.error?.status === 403 && err?.error?.error?.code === 'E2002'
      ? this.languageData.error_message3
      : err?.error?.status === 403 && err?.error?.error?.code === 'E1004'
      ? this.languageData.error_message4
      : this.languageData.error_message5;

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Uh-oh',
        descriptions: errorMessage,
        icon: 'warning',
        confirmEvent: true,
        closeEvent: true,
        actionText: 'OK'
      },
    });

    const backdropClickSubscription = dialogRef.backdropClick().subscribe(() => {
      if (err?.error?.status === 403 && err?.error?.error?.code === 'E2002') {
        this.logout();
      }
      backdropClickSubscription.unsubscribe();
    })

    const afterClosedSubscription = dialogRef.afterClosed().subscribe(() => {
      if (err?.error?.status === 403 && err?.error?.error?.code === 'E2002') {
        this.logout();
      }
      afterClosedSubscription.unsubscribe();
    })

    const dialogSubmitSubscription = dialogRef.componentInstance.confirmEvent.subscribe(() => {
      if (err?.error?.status === 403 && err?.error?.error?.code === 'E2002') {
        this.logout();
      }
      dialogSubmitSubscription.unsubscribe();
      dialogRef.close();
    });
  }

  setTableLoad(event: boolean) {
    this.onTableLoad.next(event);
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['auth', 'login']);
  }

  errorMessageTranslate(errorResp: string) {
    // // Map error messages to their translations
    const translations: any = {
      "no record to download": this.languageData.no_record_to_download,
      "duration is more than 90 days": this.languageData.duration_of_days,
      "need at least 1 connote id to add item into request pickup": this.languageData.need_at_least_one_connote,
      'enter the value in 2 decimal places': this.languageData.enter_the_value_in_2_decimal,
      'declared item weight has exceeded total parcel weight.': this.languageData.declared_weigth_has_exceeded,
      'enter parcel category details': this.languageData.enter_parcel_details,

      'document': this.languageData.document, //
      'parcel': this.languageData.parcel,
      'air parcel': this.languageData.air_parcel,
      'surface parcel': this.languageData.surface_parcel,
      'merchandise': this.languageData.merchandise, //
      'gift': this.languageData.gift, //
      'commercial sample': this.languageData.commercial_sample, //
      'returned goods': this.languageData.returned_goods,
      'others': this.languageData.others, //


    };
    return translations[errorResp?.toLowerCase()] || errorResp;
  };
  /**
 * Method Name: hideSenderAddress
 *
 * Input Parameters:
 *   - payload ({ hide: boolean }): An object containing a boolean value that determines whether the sender's address should be hidden.
 *
 * Output Parameters:
 *   - Observable<any>: Returns an observable that emits the result of the HTTP POST request.
 *
 * Purpose:
 *   - To update the user's address settings on the server, specifically whether the sender's address should be hidden.
 *
 * Author:
 *   - Ilyas Ahmed
 *
 * Description:
 *   - This method sends a POST request to update the address setting on the server.
 *   - The request body is created using the `hide` property from the input payload, and the request is sent with `Content-Type: application/json` in the headers.
 *   - The method handles potential errors using `catchError`, which logs the error to the console and returns the error as an observable.
 */

  hideSenderAddress(payload: { hide: boolean }): Observable<any> {
    // Create the request body with the `hide` property from the payload
    const body = { hide: payload.hide };
    return this.http.post<any>(
      `${this.SPPAPI}profile/addresssetting/v1/update`,
      body,
    ).pipe(
      catchError(error => {
        return of(error);
      })
    );
  }

  /**
   * Method Name: copyToClipboard
   *
   * Input Parameters:
   *   - text (string): The text to copy to the clipboard.
   *
   * Output Parameters:-
   *
   * Purpose:
   *   - Copies the provided text to the user's clipboard.
   *
   * Author: Clayton
   *
   * Description:
   *   This method creates a hidden textarea element, sets its value to the
   *   provided text, selects the text, and copies it to the clipboard using
   *   the `document.execCommand('copy')` method. Finally, it removes the
   *   temporary textarea element.
   */
  copyToClipboard(text: string) {
    const textarea = this.document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.top = '0';
    textarea.style.left = '0';
    textarea.style.opacity = '0';
    textarea.value = text;
    this.document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    this.document.body.removeChild(textarea);
  }

    /**
   * Method Name: fetchEmailByAccount
   *
   * Input Parameters:
   *   - accountNumber (string): The account number for which the associated email needs to be fetched.
   *
   * Output Parameters:
   *   - Observable<any>: An observable that emits the response from the API call, which contains the email
   *     information for the given account number, or null if an error occurs.
   *
   * Purpose:
   *   - Fetches the email associated with the provided account number from the backend service.
   *
   * Author: Saepul Latif
   *
   * Description:
   *   - This method makes an HTTP GET request to the backend API to retrieve the email associated with
   *     the provided account number. If the request fails, it catches the error, opens an error dialog
   *     notifying the user about the failure, and returns an observable emitting `null`. It uses `share()`
   *     to multicast the result for multiple subscribers.
   */
  fetchEmailByAccount(accountNumber: string): Observable<any> {
    return this.http
      .get(`${this.SPPAPI}email/v1/query?account_no=${accountNumber}`, this.header)
      .pipe(
        catchError((error) => {
          this.openErrorDialog(
            "Error Fetching Email",
            "Unable to fetch email for the provided account number. Please try again later."
          );
          return of(null);
        }),
        share()
      );
  }

  /**
   * Method Name: checkValidEmail
   *
   * Input Parameters:
   *   - email (string): The email address to validate for login.
   *   - password (string): The password associated with the email address.
   *
   * Output Parameters:
   *   - Observable<any>: An observable that emits the response from the backend indicating whether
   *     the email and password combination is valid for login.
   *
   * Purpose:
   *   - Checks if the provided email and password combination is valid for logging into the system.
   *
   * Author: Saepul Latif
   *
   * Description:
   *   - This method sends an HTTP POST request to the backend login API, passing the email and password
   *     in the request body. The response will indicate whether the login attempt was successful or not.
   */
  checkValidEmail(email: string, password: string) {
    return this.http.post<any>(`${this.SPPAPI}user/v1/login`, { email, password });
  }

}
